import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
// layouts
import HomeLayout from '../layouts/home';

import { PATH_DASHBOARD } from './paths';
// ----------------------------------------------------------------------

const HomeRoutes = {
  path: '*',
  layout: HomeLayout,
  routes: [
    {
      exact: true,
      path: '/',
      // component: lazy(() => import('../views/LandingPage'))
      component: () => <Redirect to={PATH_DASHBOARD.general.main} />
    },

    // ----------------------------------------------------------------------
    {
      exact: true,
      path: '/showcase',
      component: lazy(() => import('../views/dashboard/showcase'))
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default HomeRoutes;
