import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import fileFill from '@iconify/icons-eva/file-text-fill';
import keyIcon from '@iconify/icons-ant-design/key-outlined';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink } from 'react-router-dom';
import { alpha } from '@material-ui/core/styles';
import {
  Box,
  Avatar,
  Button,
  Divider,
  MenuItem,
  Typography
} from '@material-ui/core';
import { useRecoilState, useRecoilValue } from 'recoil';
import { logout } from '../../apis';
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import { instState, staffState } from '../../store/atom';
import * as API from '../../apis';
import ProfileButton from '../../components/button/ProfileButton';

const MENU_OPTIONS = [
  {
    label: '아리스 바로가기',
    icon: homeFill,
    linkTo: 'https://aris.club',
    isNewTab: true
  },
  {
    label: '프로필 수정',
    icon: personFill,
    linkTo: '/dashboard/institute'
  },
  {
    label: '이용 약관',
    icon: fileFill,
    linkTo: 'https://aris.club/term.txt',
    isNewTab: true
  },
  {
    label: '개인정보 처리방침',
    icon: keyIcon,
    linkTo: 'https://aris.club/privacy.txt',
    isNewTab: true
  }
];

export default function AccountPopover() {
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [staff, setStaff] = useRecoilState(staffState);
  const [institute, setInstitute] = useRecoilState(instState);

  const handleLogout = async () => {
    const result = await logout();

    if (result.auth === false) {
      setStaff(null);
      window.location.href = '/';
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '20px',
              position: 'absolute'
              // bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <ProfileButton
          size="large"
          text={institute?.title || '내 기관'}
        ></ProfileButton>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {institute?.title || ''}-{staff?.name || ''}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {staff?.email || ' - '}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => {
              if (option.isNewTab) window.open(option.linkTo, '_blank');
              else window.location.href = option.linkTo;
              handleClose();
            }}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />
            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
          >
            로그아웃
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
