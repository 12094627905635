import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { alpha, experimentalStyled } from '@material-ui/core/styles';
import {
  Box,
  AppBar,
  Hidden,
  Toolbar,
  IconButton,
  Button
} from '@material-ui/core';
import AccountPopover from './AccountPopover';
import { depthState } from '../../store/atom';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as API from '../../apis';
import { instState, currentConfId } from '../../store/atom';
import { DialogPricing } from '../../components/pricing/DialogPricing';

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = experimentalStyled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: '#fff',
  height: 88,
  borderBottom: '1px solid #d7d7d7',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = experimentalStyled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

const BreadcrumbBox = styled.div`
  font-size: 15px;
  letter-spacing: -0.8px;
  color: #646464;
`;

const BreadcrumbList = styled.div`
  display: flex;
  align-items: center;
`;

const BreadcrumbItem = styled.div`
  &:last-child span:first-child {
    margin: 0 10px;
  }
`;

const BreadcrumbArrow = styled.span`
  margin: 0 2px;
`;

const BreadcrumbWindowLink = styled.a`
  color: #646464;
  text-decoration: none;
`;

const BreadcrumbLink = styled(Link)`
  color: #646464;
  text-decoration: none;
`;

const BreadcrumbText = styled.span`
  color: #333;
`;

const OpenPricingButton = styled(Button)`
  padding: 10px 20px;
  border-radius: 50px;
`;

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardNavbar({
  onOpenSidebar
}: DashboardNavbarProps) {
  const depthArray = useRecoilValue(depthState);
  const [confId, setConfId] = useRecoilState(currentConfId);
  const [confTitle, setConfTitle] = useState<string>('');
  const [institute, setInstitute] = useRecoilState(instState);
  const [showPricingPopup, setShowPricingPopup] = useState<boolean>(false);

  useEffect(() => {
    if (!confId) {
      const query = new URLSearchParams(window.location.search);
      const passedConfId = Number(query.get('confId'));
      if (passedConfId) setConfId(passedConfId);
      return;
    }

    (async function () {
      const conferenceResult = await API.getConference(confId);
      setConfTitle(conferenceResult?.conference?.title);
    })();
  }, [confId]);

  return (
    <RootStyle>
      <ToolbarStyle>
        <Hidden lgUp>
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </Hidden>
        <BreadcrumbBox>
          <BreadcrumbList>
            <BreadcrumbItem key={100}>
              <BreadcrumbText>
                <BreadcrumbWindowLink href="/dashboard/main">
                  {institute?.title}
                </BreadcrumbWindowLink>
              </BreadcrumbText>
            </BreadcrumbItem>
            {depthArray.map((depth, i) => (
              <BreadcrumbItem key={i}>
                <BreadcrumbArrow>&gt;</BreadcrumbArrow>
                {i === depthArray.length - 1 ? (
                  <BreadcrumbText>{depth.name}</BreadcrumbText>
                ) : (
                  <BreadcrumbLink to={depth.to || './'}>
                    {depth.name}
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>
            ))}
          </BreadcrumbList>
        </BreadcrumbBox>
        <Box
          sx={{
            display: 'flex',
            marginLeft: 'auto',
            alignItems: 'center',
            '& > *:not(:first-of-type)': {
              ml: { xs: 1.5, sm: 2, lg: 3 }
            }
          }}
        >
          <Box>
            <OpenPricingButton
              variant="outlined"
              onClick={() => setShowPricingPopup(true)}
            >
              아리스 유료 플랜
            </OpenPricingButton>
          </Box>
          <AccountPopover />
        </Box>
      </ToolbarStyle>
      <DialogPricing
        isOpen={showPricingPopup}
        close={() => setShowPricingPopup(false)}
      />
    </RootStyle>
  );
}
