// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_CONFERENCE = '/conference';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  join: path(ROOTS_AUTH, '/join'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected')
};

export const PATH_HOME = {
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  purchase: 'https://material-ui.com/store/items/minimal-dashboard/',
  components: '/components',
  dashboard: ROOTS_DASHBOARD
};

export const PATH_PAGE = {
  page404: '/404'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    samplePage: path(ROOTS_DASHBOARD, '/sample'),
    main: path(ROOTS_DASHBOARD, '/main'),
    conference: path(ROOTS_DASHBOARD, '/conference'),
    committee: path(ROOTS_DASHBOARD, '/attendee'),
    sponsor: path(ROOTS_DASHBOARD, '/sponsor'),
    qna: path(ROOTS_DASHBOARD, '/qna'),
    institute: path(ROOTS_DASHBOARD, '/institute'),
    email: path(ROOTS_DASHBOARD, '/email'),
    showcase: path(ROOTS_DASHBOARD, '/showcase')
  },
  app: {
    root: path(ROOTS_DASHBOARD, '/drop'),
    pageFour: path(ROOTS_DASHBOARD, '/drop/four'),
    pageFive: path(ROOTS_DASHBOARD, '/drop/five'),
    pageSix: path(ROOTS_DASHBOARD, '/drop/six'),
    main: path(ROOTS_DASHBOARD, '/main'),
    participants: path(ROOTS_DASHBOARD, '/participant')
  }
};

export const PATH_CONFERENCE = {
  root: ROOTS_CONFERENCE,
  general: {
    conferenceStatistic: path(ROOTS_CONFERENCE, '/conferenceStatistic'),
    addConference: path(ROOTS_CONFERENCE, '/conference'),
    addAttendee: path(ROOTS_CONFERENCE, '/attendee'),
    addCommittee: path(ROOTS_CONFERENCE, '/committee'),
    addLocation: path(ROOTS_CONFERENCE, '/location'),
    bookmarker: path(ROOTS_CONFERENCE, '/bookmarker'),
    addProgram: path(ROOTS_CONFERENCE, '/program'),
    addNews: path(ROOTS_CONFERENCE, '/news'),
    addNewsDetail: path(ROOTS_CONFERENCE, '/newsDetail'),
    videoAd: path(ROOTS_CONFERENCE, '/videoAd'),
    ticket: path(ROOTS_CONFERENCE, '/ticket')
  }
};
