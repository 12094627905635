import { ReactNode, useEffect, useState } from 'react';
import { useLocation, matchPath, Link, useHistory } from 'react-router-dom';
// material
import { experimentalStyled } from '@material-ui/core/styles';
import { Box, Button, Drawer, Hidden, Typography } from '@material-ui/core';
// components
import Scrollbar from '../../components/Scrollbar';
//
import * as API from '../../apis';
import styled, { css } from 'styled-components';
import BasicButton from '../../components/button/BasicButton';
// ----------------------------------------------------------------------
import { ReactComponent as ConferenceIcon } from '../../assets/ic_conference.svg';
import { ReactComponent as SchoolIcon } from '../../assets/ic_school.svg';
import { ReactComponent as SponsorIcon } from '../../assets/ic_sponsor.svg';
import { DialogAddConference } from './DialogAddConference';
// ----------------------------------------------------------------------
import { ArrowForwardIos, ContactSupport } from '@material-ui/icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { instState, instIdState } from '../../store/atom';

const DRAWER_WIDTH = 280;

const RootStyle = experimentalStyled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const QnaBox = styled(Box)`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 16px;
  width: 250px;
  height: 90px;
  margin: 12px;
  padding: 15px;
  margin-top: 140px;
  transition-duration: 0.5s;
  &:hover,
  &:focus {
    cursor: pointer;
    background-color: #d6d6d6;
  }
`;
const QnaTextBox = styled(Box)`
  flex-grow: 1;
`;
const QnaText = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
`;
const QnaLink = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px;
  margin-top: 10px;
  margin-bottom: 5px;
  color: #979797;
  font-size: 14px;
`;
const QnaNum = styled(Typography)`
  padding-bottom: 2px;
  margin-left: 15px;
  margin-right: 5px;
  color: red;
  font-size: 24px;
  font-weight: 400;
`;

// ----------------------------------------------------------------------

const LogoBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 14px 27px;
  box-sizing: border-box;
  height: 88px;
  width: 280px;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #d7d7d7;
  align-items: center;
  justify-content: center;
  color: rgba(40, 42, 44, 0.68);
  cursor: pointer;
`;

const LogoImage = styled.div<{ backgroundUrl: string }>`
  width: 100%;
  height: 100%;
  ${({ backgroundUrl }) => css`
    background-image: url(${backgroundUrl});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  `}
`;

const LogoText = styled.h1`
  padding-left: 10px;
  font-size: 20px;
  line-height: 20px;
`;

const SidebarContents = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #d7d7d7;
  width: 280px;
  height: calc(100vh - 88px);
`;

const CreateConferenceBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px;
  margin-top: 24px;
`;

const MenuGroupTitle = styled.div`
  margin-top: 24px;
  text-transform: uppercase;
  padding-left: 40px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.2px;
  font-weight: bold;
`;

const MenuList = styled.ul`
  margin-top: 16px;
`;

const MenuItem = styled.li``;

const MenuLink = styled(Link)<{ path: string; to: string }>`
  display: flex;
  align-items: center;
  padding: 12px 40px;
  position: relative;
  text-decoration: none;
  color: #465058;
  font-size: 14px;
  line-height: 14px;
  font-weight: normal;
  svg {
    fill: #637381;
    margin-right: 16px;
  }
  &:after {
    position: absolute;
    display: inline-block;
    content: '';
    height: 100%;
    width: 3px;
    top: 0%;
    right: -1px;
    background-color: #ffffff00;
    border-radius: 2px 0px 0px 2px;
  }
  ${({ path, to }) => {
    if (path === to.split('?')[0]) {
      return css`
        color: #4052ff;
        background-color: rgba(76, 95, 239, 0.08);
        font-weight: bold;
        &:after {
          background-color: #4052ff;
        }
        svg {
          fill: #4052ff;
        }
      `;
    }
  }}
`;

// ----------------------------------------------------------------------

type NavBarProps = {
  isOpenSidebar?: boolean;
  onCloseSidebar?: VoidFunction;
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar
}: NavBarProps) {
  const instId = useRecoilValue(instIdState);
  const [loading, setLoading] = useState<boolean>(true);
  const [leftQna, setLeftQna] = useState<number>(0);

  const [institute, setInstitute] = useRecoilState(instState);
  const [showDialog, setShowDialog] = useState<string>(''); // 참석자 추가/수정 팝업 보이기/숨기기

  useEffect(() => {
    (async function () {
      if (instId !== null) {
        const maxLength = await API.getAllQna(instId);
        let count = 0;
        const qnaData = await API.getAllQna(
          instId,
          0,
          maxLength.qnas.maxLength
        ); // get all qna
        qnaData.qnas.data.forEach((el: any) => {
          if (!el.answer) {
            count++;
          }
        });
        setLeftQna(count);
      }
      setLoading(false);
    })();
  }, []);
  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, showDialog]);

  const history = useHistory();

  const renderContent = (
    <Scrollbar>
      <LogoBox
        onClick={() => {
          window.location.href = '/dashboard/main';
        }}
      >
        {institute?.thumbnail && (
          <LogoImage backgroundUrl={institute?.thumbnail}></LogoImage>
        )}
        {!institute?.thumbnail && <LogoText>{institute?.title}</LogoText>}
      </LogoBox>
      <SidebarContents>
        <DialogAddConference
          isOpen={showDialog}
          close={() => setShowDialog('')}
        />
        <CreateConferenceBox>
          <BasicButton
            size="large"
            text="신규 학술대회 개설하기"
            fill
            onClick={() => {
              setShowDialog('modify');
            }}
          />
        </CreateConferenceBox>
        <MenuGroupTitle>general</MenuGroupTitle>
        <MenuList>
          <MenuItem>
            <MenuLink path={history.location.pathname} to="/dashboard/main">
              <ConferenceIcon />
              <span>학술대회</span>
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink path={history.location.pathname} to="/dashboard/attendee">
              <SchoolIcon />
              <span>학회 회원</span>
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink path={history.location.pathname} to="/dashboard/sponsor">
              <SponsorIcon />
              <span>후원사/유관 기관</span>
            </MenuLink>
          </MenuItem>
        </MenuList>
        <QnaBox onClick={() => (window.location.href = '/dashboard/qna')}>
          <QnaTextBox>
            <Box display="flex" alignItems="center">
              <QnaText>미응답 문의사항</QnaText>
              <QnaNum>{leftQna === 0 ? '-' : leftQna}</QnaNum>
              <QnaText>건</QnaText>
              <ContactSupport
                sx={{
                  position: 'absolute',
                  right: '15px',
                  backgroundColor: '#4052ff',
                  padding: '3px',
                  borderRadius: '100%',
                  color: '#ffffff',
                  fontSize: '24px'
                }}
              />
            </Box>
            <QnaLink>
              <Typography variant="body2">문의사항 확인하기</Typography>
              <ArrowForwardIos sx={{ fontSize: '12px', marginTop: '3px' }} />
            </QnaLink>
          </QnaTextBox>
        </QnaBox>
      </SidebarContents>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <Hidden lgUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, border: 'none' }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              border: 'none'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </RootStyle>
  );
}
