import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

const sizeStyle = function (s: 'large' | 'medium' | 'small') {
  if (s === 'large') {
    return css`
      padding: 8px 20px;
      font-weight: bold;
      font-size: 14px;
      line-height: 26px;
    `;
  } else if (s === 'medium') {
    return css`
      padding: 5px 15px;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: -0.4px;
    `;
  } else {
    return css`
      padding: 3px 10px;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: -0.4px;
    `;
  }
};

const buttonStyle = function ({
  disabled,
  fill,
  disabledOutline,
  outline,
  underline
}: any) {
  if (disabled) {
    return css`
      background-color: #a7a7a7;
      border-color: #a7a7a7;
      color: #fff;
    `;
  } else if (fill) {
    return css`
      background-color: #4052ff;
      border-color: #4052ff;
      color: #fff;
      &:hover {
        background-color: #1f32c1;
        transition: all 0.3s;
      }
    `;
  } else if (outline) {
    return css`
      background-color: #fff;
      border-color: #4052ff;
      color: #4052ff;
      &:hover {
        border-color: #1f32c1;
        color: #1f32c1;
        transition: all 0.3s;
      }
    `;
  } else if (disabledOutline) {
    return css`
      background-color: #fff;
      border-color: #a7a7a7;
      color: #a7a7a7;
      &:hover {
        border-color: #a7a7a7;
        color: #a7a7a7;
        transition: all 0.3s;
      }
    `;
  } else if (underline) {
    return css`
      background-color: #fff;
      border: 1px solid #fff;
      border-bottom: 1px solid #353535;
      color: #353535;
      border-radius: 0;
      padding: 0;
    `;
  } else {
    return css`
      background-color: #fff;
      border-color: #fff;
      color: #4052ff;
      &:hover {
        color: #1f32c1;
        transition: all 0.3s;
      }
    `;
  }
};

const Button = styled.button<{
  size: 'large' | 'medium' | 'small';
  disabled?: boolean;
  fill?: boolean;
  disabledOutline?: boolean;
  outline?: boolean;
  underline?: boolean;
}>`
  border-radius: 4px;
  border: 1px solid #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ size }) => sizeStyle(size)}
  ${({ disabled, disabledOutline, fill, outline, underline }) =>
    buttonStyle({ disabled, disabledOutline, fill, outline, underline })}
`;

interface Props {
  type?: 'button' | 'submit' | 'reset';
  text: string;
  size: 'large' | 'medium' | 'small';
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  disabled?: boolean;
  fill?: boolean;
  outline?: boolean;
  disabledOutline?: boolean;
  underline?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export default function BasicButton({
  type,
  size,
  leftIcon,
  rightIcon,
  disabled,
  disabledOutline,
  fill,
  outline,
  underline,
  text,
  onClick
}: Props): ReactElement {
  return (
    <Button
      size={size}
      type={type || 'button'}
      disabled={disabled}
      fill={fill}
      disabledOutline={disabledOutline}
      outline={outline}
      underline={underline}
      onClick={onClick}
    >
      {leftIcon}
      {text}
      {rightIcon}
    </Button>
  );
}
