export const ROOT_URL = process.env.REACT_APP_SERVER_URL;

export const fetchGet = async (api: string | undefined): Promise<Response> => {
  if (!api) throw new Error('No request object');

  try {
    const res: Response = await fetch(`${ROOT_URL}${api}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    return res;
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchPost = async (
  body: Record<string, unknown> | undefined,
  api: string | undefined,
  signal?: AbortSignal | undefined
): Promise<Response> => {
  if (!body || !api) throw new Error('No request object');

  try {
    const res: Response = await fetch(`${ROOT_URL}${api}`, {
      signal,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
      credentials: 'include'
    });
    return res;
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchPut = async (
  body: Record<string, unknown> | undefined,
  api: string | undefined,
  signal?: AbortSignal | undefined
): Promise<Response> => {
  if (!body || !api) throw new Error('No request object');

  try {
    const res: Response = await fetch(`${ROOT_URL}${api}`, {
      signal,
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
      credentials: 'include'
    });
    return res;
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchDelete = async (
  api: string | undefined
): Promise<Response> => {
  if (!api) throw new Error('No request object');

  try {
    const res: Response = await fetch(`${ROOT_URL}${api}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    return res;
  } catch (err) {
    throw new Error(err);
  }
};
