import React, { ReactElement, ReactFragment } from 'react';
import { atom, selector } from 'recoil';
import {
  Conference,
  Institute,
  Location,
  News,
  Presentation,
  Session,
  Staff,
  Track
} from '../schemas/types';

interface Depth {
  name: string;
  to: string;
}

export const depthState = atom<Depth[]>({
  key: 'depth',
  default: []
});

export const currentInstId = atom<number>({
  key: 'currentInstId',
  default: 0
});

export const currentConfId = atom<number>({
  key: 'currentConfId',
  default: 0
});
type ThemeMode = 'light' | 'dark';
type ThemeDirection = 'rtl' | 'ltr';

const ThemeModeState = atom<ThemeMode>({
  key: 'ThemeMode',
  default: 'light'
});

const ThemeDirectionState = atom<ThemeDirection>({
  key: 'ThemeDirection',
  default: 'ltr'
});

const staffState = atom<Staff | null>({
  key: 'staffState',
  default: null
});

const instState = atom<Institute | null>({
  key: 'instState',
  default: null
});

const instIdState = selector<number | null>({
  key: 'instIdState',
  get: ({ get }) => {
    const staff = get(staffState);
    return staff?.instituteId || null;
  }
});

const loginLoadingState = atom<boolean | null>({
  key: 'loadingState',
  default: false
});

const trackListState = atom<Track[]>({
  key: 'trackListState',
  default: []
});

const locationListState = atom<Location[]>({
  key: 'locationListState',
  default: []
});

const presentationListState = atom<Presentation[]>({
  key: 'presentationListState',
  default: []
});

const sessionState = atom<Session | null>({
  key: 'sessionState',
  default: null
});

export {
  ThemeModeState,
  ThemeDirectionState,
  staffState,
  loginLoadingState,
  instState,
  instIdState,
  trackListState,
  locationListState,
  presentationListState,
  sessionState
};
