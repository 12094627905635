// material
import * as Yup from 'yup';
import { fbt } from 'fbt';
// material
import {
  Box,
  TextField,
  MenuItem,
  Typography,
  Dialog,
  FormControl,
  CardHeader,
  CardContent,
  Card,
  InputAdornment
} from '@material-ui/core';
import styled, { css } from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { instIdState } from '../../store/atom';
// components
import { Form, Formik, FormikProvider, useFormik } from 'formik';

import * as API from '../../apis';
import BasicButton from '../../components/button/BasicButton';

const StyledCard = styled(Card)`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const RowBox = styled(Box)<{ layer: number }>`
  display: flex;
  flex-direction: row;
  ${({ layer }) => css`
    & > * {
      display: flex;
      flex: 1 auto;
      margin-right: 24px;
      width: ${60 / layer}%;
    }
    & > *:last-child {
      margin-right: 0;
    }
  `}
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  float: right;
  margin-bottom: 10px;
`;

interface Props {
  isOpen: string;
  close: () => void;
}

export const DialogAddConference = ({ isOpen, close }: Props) => {
  const instId: any = useRecoilValue(instIdState);

  const Schema = Yup.object().shape({
    title: Yup.string().required(fbt('타이틀 입력은 필수입니다.', '')),
    location: Yup.string().required(fbt('개최 장소 입력은 필수입니다.', ''))
  });

  type InitialValues = {
    id: number;
    title: string;
    area: string | null;
    urlPath: string | null;
    isOnline: number;
    location: string;
    instituteId: number;
  };

  const formik = useFormik<InitialValues>({
    initialValues: {
      id: 0,
      title: '',
      area: null,
      urlPath: null,
      isOnline: 0,
      location: '',
      instituteId: instId
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      try {
        if (values.urlPath) {
          const regex = /^(?=.*?[A-Za-z])[A-Za-z|0-9|]+$/;
          if (!regex.test(values.urlPath)) {
            errors.urlPath = '영문과 숫자만 입력해주세요.(영문 1자 필수)';
            return;
          }
        }

        const result = await API.addConference(values);

        if (result.error) {
          console.error(`onSubmit error: ${result.error}`);
          if (result.error == 'duplicatedUrlPath') {
            errors.urlPath = '이미 존재하는 url입니다.';
          }
        } else {
          window.location.href =
            '/conference/conference?confId=' + result.result.insertId;
        }
      } catch (e) {
        console.error(e);
      }
    }
  });

  const { errors } = formik;

  /*
  function isOneDayConf(checked: boolean) {
    if (checked) {
      formik.setValues({
        ...values,
        endDate: values.startDate.slice(0, 11) + '23:59'
      });
      setOneDayConf(true);
    } else {
      setOneDayConf(false);
    }
  }
  */

  return (
    <Dialog
      open={isOpen === 'add' || isOpen === 'modify'}
      fullWidth={true}
      maxWidth="sm"
    >
      <StyledCard>
        <CardHeader title="학술대회 간편 개설" />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
              <Box display="flex" flexDirection="row" marginBottom="20px">
                <Box flex="1" width="100%">
                  <TextField
                    label="타이틀"
                    variant="outlined"
                    style={{ width: '100%' }}
                    {...formik.getFieldProps('title')}
                    error={Boolean(formik.touched.title && formik.errors.title)}
                    helperText={
                      (formik.touched.title && formik.errors?.title) || ''
                    }
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" marginBottom="15px">
                <FormControl variant="outlined">
                  {
                    <TextField
                      id="area"
                      select
                      label="유형 선택"
                      {...formik.getFieldProps('area')}
                      InputProps={{
                        sx: {
                          paddingTop: '16px'
                        }
                      }}
                    >
                      <MenuItem value="1">인문학</MenuItem>
                      <MenuItem value="2">사회과학</MenuItem>
                      <MenuItem value="3">자연과학</MenuItem>
                      <MenuItem value="4">공학</MenuItem>
                      <MenuItem value="5">의약학</MenuItem>
                      <MenuItem value="6">농수해양학</MenuItem>
                      <MenuItem value="7">예술체육학</MenuItem>
                      <MenuItem value="8">복합학</MenuItem>
                      <MenuItem value="9">기타</MenuItem>
                    </TextField>
                  }
                </FormControl>
              </Box>
              <Box display="flex" flexDirection="row" marginBottom="20px">
                <Box flex="1" width="100%">
                  <TextField
                    label="URL"
                    variant="outlined"
                    style={{ width: '100%' }}
                    {...formik.getFieldProps('urlPath')}
                    error={Boolean(
                      formik.touched.urlPath && formik.errors.urlPath
                    )}
                    helperText={
                      (formik.touched.urlPath && formik.errors?.urlPath) || ''
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          https://aris.club/conference/
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
              </Box>
              <RowBox layer={2} marginBottom="20px">
                <FormBox>
                  <Typography
                    style={{ color: 'grey' }}
                    variant="body1"
                    gutterBottom
                  >
                    시작일
                  </Typography>
                  {
                    <TextField
                      type="datetime-local"
                      {...formik.getFieldProps('startDate')}
                    />
                  }
                </FormBox>
                <FormBox marginRight="24px">
                  <Typography
                    style={{ color: 'grey' }}
                    variant="body1"
                    gutterBottom
                  >
                    종료일
                  </Typography>
                  {
                    <TextField
                      type="datetime-local"
                      {...formik.getFieldProps('endDate')}
                    />
                  }
                </FormBox>
              </RowBox>
              <Box display="flex" flexDirection="column" marginBottom="20px">
                <FormControl variant="outlined">
                  {
                    <TextField
                      id="isOnline"
                      select
                      label="개최 방식"
                      {...formik.getFieldProps('isOnline')}
                      InputProps={{
                        sx: {
                          paddingTop: '8px',
                          paddingBottom: '8px'
                        }
                      }}
                    >
                      <MenuItem value={0}>오프라인</MenuItem>
                      <MenuItem value={1}>온라인</MenuItem>
                      <MenuItem value={2}>하이브리드</MenuItem>
                    </TextField>
                  }
                </FormControl>
              </Box>
              {formik.values.isOnline === 1 ? null : (
                <>
                  <Box display="flex" flexDirection="row" marginBottom="20px">
                    <Box flex="1" width="100%">
                      <TextField
                        label="개최 장소"
                        variant="outlined"
                        style={{ width: '100%' }}
                        {...formik.getFieldProps('location')}
                        error={Boolean(
                          formik.touched.location && formik.errors.location
                        )}
                        helperText={
                          (formik.touched.location &&
                            formik.errors?.location) ||
                          ''
                        }
                      />
                    </Box>
                  </Box>
                </>
              )}
              <Box display="flex" flexDirection="row" marginBottom="5px">
                <Typography variant="caption" display="block" gutterBottom>
                  # 학술대회 간편 개설 이후, 상세한 정보 등록 및 관리가
                  가능합니다.
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row">
                <Box flex="1" width="100%" textAlign="right">
                  <ButtonContainer>
                    <BasicButton
                      text="취소"
                      size="large"
                      onClick={() => {
                        close();
                        formik.resetForm();
                      }}
                      outline
                    ></BasicButton>
                    <div style={{ marginLeft: '10px' }}>
                      <BasicButton
                        text="학술대회 개설"
                        type="submit"
                        size="large"
                        onClick={() => formik.handleSubmit}
                        fill
                      ></BasicButton>
                    </div>
                  </ButtonContainer>
                </Box>
              </Box>
            </Form>
          </FormikProvider>
        </CardContent>
      </StyledCard>
    </Dialog>
  );
};
