import { ReactNode, useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
// import useAuth from '../hooks/useAuth';
import { useRecoilState } from 'recoil';
import { PATH_AUTH } from '../routes/paths';
import LoadingScreen from '../components/LoadingScreen';
import { getInstitute, getStaffInfo } from '../apis';

// ----------------------------------------------------------------------
import { instState, staffState, loginLoadingState } from '../store/atom';

type AuthProtectProps = {
  children: ReactNode;
};

export default function AuthProtect({ children }: AuthProtectProps) {
  const [isLoginLoading, setIsLoginLoading] = useRecoilState(loginLoadingState);
  const [staff, setStaff] = useRecoilState(staffState);
  const [institute, setInstitute] = useRecoilState(instState);
  const [loginCheck, setLoginCheck] = useState(false);

  // useEffect(() => {
  //   alert(`useEffect(staff) called - staff: ${JSON.stringify(staff)}`);
  // }, [staff]);

  useEffect(() => {
    if (!staff) {
      (async () => {
        try {
          const result = await getStaffInfo();
          const instResult = await getInstitute();

          if (result && result.id) {
            setStaff(result);
            setInstitute(instResult.institute);
            console.log(
              '[AuthGuard] Getting staff info success스태프 정보 가져오기 성공'
            );
          }
          setLoginCheck(true);
        } catch (e) {
          console.log(
            `[useAuth] staff info retrieval fail - No staff is logged or Error occured. Error:${JSON.stringify(
              e
            )}`
          );
        }
      })();
    }
  }, [staff]);

  return (
    <>
      {isLoginLoading ? <LoadingScreen /> : null}
      {loginCheck && staff ? children : null}
      {loginCheck && !staff ? <Redirect to={PATH_AUTH.login} /> : null}
    </>
  );
}
