import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

const sizeStyle = function (s: 'large' | 'medium' | 'small') {
  if (s === 'large') {
    return css`
      padding: 8px 20px;
      font-weight: bold;
      font-size: 14px;
      line-height: 26px;
    `;
  } else if (s === 'medium') {
    return css`
      padding: 5px 15px;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: -0.4px;
    `;
  } else {
    return css`
      padding: 3px 10px;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: -0.4px;
    `;
  }
};

const buttonStyle = function ({ disabled }: any) {
  return css`
    background-color: white;
    border-color: #a7a7a7;
    color: #a7a7a7;
    &:hover {
      border-color: #1f32c1;
      color: #1f32c1;
      transition: all 0.3s;
    }
  `;
};

const Button = styled.button<{
  size: 'large' | 'medium' | 'small';
}>`
  border-radius: 20px;
  border: 2px solid #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ size }) => sizeStyle(size)}
  ${({ disabled }) => buttonStyle({ disabled })}
`;

interface Props {
  type?: 'button' | 'submit' | 'reset';
  text: string;
  size: 'large' | 'medium' | 'small';
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  disabled?: boolean;
  fill?: boolean;
  outline?: boolean;
  underline?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export default function ProfileButton({
  type,
  size,
  leftIcon,
  rightIcon,
  disabled,
  fill,
  outline,
  underline,
  text,
  onClick
}: Props): ReactElement {
  return (
    <Button
      size={size}
      type={type || 'button'}
      disabled={disabled}
      onClick={onClick}
    >
      {leftIcon}
      {text}
      {rightIcon}
    </Button>
  );
}
