import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { PATH_DASHBOARD } from './paths';

import AuthGuard from '../guards/AuthGuard';
// layouts
import DashboardLayout from '../layouts/dashboard';

// ----------------------------------------------------------------------

const DashboardRoutes = {
  path: PATH_DASHBOARD.root,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    // {
    //   exact: true,
    //   path: '/',
    //   component: lazy(() => import('../views/LandingPage'))
    // },

    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.general.samplePage,
      component: lazy(() => import('../views/SamplePage'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.main,
      component: lazy(() => import('../views/dashboard/Main'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.conference,
      component: lazy(() => import('../views/dashboard/Conference'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.committee,
      component: lazy(() => import('../views/dashboard/Attendee'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.sponsor,
      component: lazy(() => import('../views/dashboard/Sponsor'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.qna,
      component: lazy(() => import('../views/dashboard/Qna'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.institute,
      component: lazy(() => import('../views/dashboard/Institute'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.email,
      component: lazy(() => import('../views/dashboard/Email'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.showcase,
      component: lazy(() => import('../views/dashboard/showcase'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.root,
      component: () => <Redirect to={PATH_DASHBOARD.general.main} />
    },

    // APP
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.app.pageFour,
      component: lazy(() => import('../views/PageFour'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.app.pageFive,
      component: lazy(() => import('../views/PageFive'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.app.pageSix,
      component: lazy(() => import('../views/PageSix'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.app.participants,
      component: lazy(() => import('../views/Participants'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.app.main,
      component: lazy(() => import('../views/dashboard/Main'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.app.root,
      component: () => <Redirect to={PATH_DASHBOARD.app.pageFour} />
    },

    // ----------------------------------------------------------------------

    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default DashboardRoutes;
