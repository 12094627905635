import { Conference } from '../schemas/types';
import { fetchDelete, fetchGet, fetchPost, fetchPut } from './common';

const testURL = 'https://jsonplaceholder.typicode.com/posts';
// GET (List) - https://jsonplaceholder.typicode.com/posts
// GET (One) - https://jsonplaceholder.typicode.com/posts/1
// POST - https://jsonplaceholder.typicode.com/posts
// PUT - https://jsonplaceholder.typicode.com/posts/1
// DELETE - https://jsonplaceholder.typicode.com/posts/1

const serverURL = process.env.REACT_APP_SERVER_URL;

// Login

export const login = function (email: string, password: string) {
  return fetch(`${serverURL}/api/login`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, password }),
    credentials: 'include'
  }).then((res) => res.json());
};

export const logout = function () {
  return fetch(`${serverURL}/api/logout`, {
    credentials: 'include'
  }).then((res) => res.json());
};

export const signUp = function (newStaff: any) {
  return fetch(`${serverURL}/api/signup`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ newStaff: newStaff }),
    credentials: 'include'
  }).then((res) => res.json());
};

export const sendVerifiedEmail = function (email: string) {
  return fetch(`${serverURL}/api/verify-email`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email: email }),
    credentials: 'include'
  }).then((res) => res.json());
};

export const getInstitute = function () {
  return fetch(`${serverURL}/api/institute/staff`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const updateInstitute = function (instId: number, instObj: Object) {
  return fetch(`${serverURL}/api/institute/${instId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ institute: instObj }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const getStaffInfo = function () {
  return fetch(`${serverURL}/api/staff`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const updateStaffPassword = function (
  currentPassword: string,
  newPassword: string
) {
  return fetch(`${serverURL}/api/staff/password`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ currentPassword, newPassword }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const addConference = function (newConference: any) {
  return fetch(`${serverURL}/api/conference`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ newConference: newConference }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const getConference = function (confId: number) {
  return fetch(`${serverURL}/api/conference/${confId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const deleteConference = function (confId: number) {
  return fetch(`${serverURL}/api/conference/${confId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      newConference: { isDel: true }
    }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const deleteCustomPage = function (customPageId: number) {
  return fetch(`${serverURL}/api/customPage/${customPageId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const updateCustomPage = function (
  customPageId: number,
  newCustomPage: any
) {
  return fetch(`${serverURL}/api/customPage/${customPageId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      newCustomPage: newCustomPage
    }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const addCustomPage = function (confId: number, newCustomPage: any) {
  return fetch(`${serverURL}/api/conference/${confId}/customPage`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      newCustomPage: newCustomPage
    }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const getConferences = function () {
  return fetch(`${serverURL}/api/staff/conference`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const getConferencesByInstId = function (instId: number) {
  return fetch(`${serverURL}/api/conference/institute/${instId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const getCommittesByConfTitle = function (confTitle: string) {
  return fetch(`${serverURL}/api/conference/committee/${confTitle}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const getAttendeesByInstId = function (instId: number) {
  return fetch(`${serverURL}/api/institute/${instId}/attendee`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const getAllAttendees = function (pageIdx: number, rowsPerPage: number) {
  return fetch(
    `${serverURL}/api/attendee/paginate?pageidx=${pageIdx}&rowperpage=${rowsPerPage}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  ).then((response) => response.json());
};

export const searchAttendee = function (
  searchValue: string,
  pageIdx: number,
  rowsPerPage: number
) {
  return fetch(
    `${serverURL}/api/attendee/search?name=${searchValue}&pageidx=${pageIdx}&rowperpage=${rowsPerPage}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  ).then((response) => response.json());
};

export const getAttendees = function (confId: number) {
  return fetch(`${serverURL}/api/conference/${confId}/attendee`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const getPaginatedAttendees = function (
  confId: number,
  pageIdx: number,
  rowsPerPage: number
) {
  return fetch(
    `${serverURL}/api/conference/${confId}/attendee/paginate?pageidx=${pageIdx}&rowperpage=${rowsPerPage}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  ).then((response) => response.json());
};

export const addNewAttendee = function (confId: number, newAttendee: Object) {
  return fetch(`${serverURL}/api/conference/${confId}/attendee`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(newAttendee),
    credentials: 'include'
  }).then((response) => response.json());
};

export const updateAttendee = function (
  confId: number,
  attendeeId: number,
  updatedAttendee: Object
) {
  return fetch(`${serverURL}/api/conference/${confId}/attendee/${attendeeId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ updatedAttendee }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const deleteAttendee = function (confId: number, attendeeId: number) {
  return fetch(`${serverURL}/api/conference/${confId}/attendee/${attendeeId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const searchConfAttendee = function (
  confId: number,
  pageIdx: number,
  rowsPerPage: number,
  searchValue?: string,
  selectedTicket?: any
) {
  return fetch(
    `${serverURL}/api/conference/${confId}/attendee/search?name=${searchValue}${
      selectedTicket ? `&selectedTicket=${selectedTicket}` : ''
    }&pageidx=${pageIdx}&rowperpage=${rowsPerPage}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  ).then((response) => response.json());
};

export const getCommittees = function (confId: number) {
  return fetch(`${serverURL}/api/conference/${confId}/committee`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const getCommittee = function (
  confId: number,
  pageIdx = 0,
  rowsPerPage = 5
) {
  return fetch(
    `${serverURL}/api/conference/${confId}/committee/paginate?pageidx=${pageIdx}&rowperpage=${rowsPerPage}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  ).then((response) => response.json());
};

export const addCommittee = function (confId: number, committee: any) {
  return fetch(`${serverURL}/api/conference/${confId}/committee`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(committee),
    credentials: 'include'
  }).then((response) => response.json());
};

export const updateCommittee = function (committee: any) {
  const { name, organization, jobTitle, email, roleId, phone } = committee;
  return fetch(
    `${serverURL}/api/conference/${committee.conferenceId}/committee/${committee.id}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name,
        organization,
        jobTitle,
        email,
        roleId,
        phone
      }),
      credentials: 'include'
    }
  ).then((response) => response.json());
};

export const deleteCommittee = function (committee: any) {
  return fetch(
    `${serverURL}/api/conference/${committee.conferenceId}/committee/${committee.id}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  ).then((response) => response.json());
};

export const searchConfCommittee = function (
  confId: number,
  searchValue: string,
  pageIdx: number,
  rowsPerPage: number
) {
  return fetch(
    `${serverURL}/api/conference/${confId}/committee/search?name=${searchValue}&pageidx=${pageIdx}&rowperpage=${rowsPerPage}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  ).then((response) => response.json());
};

export const getBookmarkers = (confId: number) => {
  return fetch(`${serverURL}/api/conference/${confId}/bookmarker`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const getNews = (confId: number) => {
  return fetch(`${serverURL}/api/conference/${confId}/news`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const getNewsInfo = (confId: number, newsId: number) => {
  return fetch(`${serverURL}/api/conference/${confId}/news/${newsId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const deleteNews = (confId: number, newsId: number) => {
  return fetch(`${serverURL}/api/conference/${confId}/news/${newsId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const getCommitteeRole = (confId: number) => {
  return fetch(`${serverURL}/api/conference/${confId}/role`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const getReadableRoles = (confId: number, newsId: number) => {
  return fetch(
    `${serverURL}/api/conference/${confId}/news/${newsId}/readableRoles`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  ).then((response) => response.json());
};

export const addNews = (confId: number, news: any) => {
  return fetch(`${serverURL}/api/conference/${confId}/news`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(news),
    credentials: 'include'
  }).then((response) => response.json());
};

export const updateNews = (confId: number, news: any) => {
  const { title, description, assignee } = news;
  return fetch(`${serverURL}/api/conference/${confId}/news/${news.id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      title,
      description,
      assignee
    }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const getSessionPlaces = function (confId: number) {
  return fetch(`${serverURL}/api/conference/${confId}/sessionPlace`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const addSessionPlace = function (confId: number, title: any) {
  return fetch(`${serverURL}/api/conference/${confId}/sessionPlace`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ title: title }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const deleteSessionPlace = function (
  confId: number,
  sessionPlaceId: number
) {
  return fetch(
    `${serverURL}/api/conference/${confId}/sessionPlace/${sessionPlaceId}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  ).then((response) => response.json());
};

export const updateSessionPlacePriority = function (
  sessionPlaceId: number,
  priority: number
) {
  return fetch(`${serverURL}/api/sessionPlace/${sessionPlaceId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      priority: priority
    }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const addConfLocation = function (
  confId: number,
  type: string,
  description: any
) {
  return fetch(`${serverURL}/api/conference/${confId}/confLocation`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      type: type,
      description: description
    }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const getConfLocationsByConfId = function (confId: number) {
  return fetch(`${serverURL}/api/conference/${confId}/confLocation`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const updateConfLocationByConfId = function (
  confId: number,
  confLocationId: number,
  description: string
) {
  return fetch(
    `${serverURL}/api/conference/${confId}/confLocation/${confLocationId}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        description: description
      }),
      credentials: 'include'
    }
  ).then((response) => response.json());
};

export const updateConfLoactionPriority = function (
  confLocationId: number,
  priority: number
) {
  return fetch(`${serverURL}/api/confLocation/${confLocationId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      priority: priority
    }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const updateConference = function (
  id: number,
  newConference: Conference
) {
  return fetch(`${serverURL}/api/conference/${id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      newConference: newConference
    }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const deleteConfLocation = function (confLocId: number) {
  return fetch(`${serverURL}/api/confLocation/${confLocId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const getTracks = async (confId: number) => {
  try {
    const result = await fetchGet(`/api/conference/${confId}/track`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const addTrack = async (confId: number, trackObj: any) => {
  try {
    const result = await fetchPost(trackObj, `/api/conference/${confId}/track`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const deleteTrack = async (confId: number, trackId: number) => {
  try {
    const result = await fetchDelete(
      `/api/conference/${confId}/track/${trackId}`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getLocations = async (confId: number) => {
  try {
    const result = await fetchGet(`/api/conference/${confId}/location`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getPresentations = async (sessionId: number) => {
  try {
    const result = await fetchGet(`/api/session/${sessionId}/presentation`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const addSession = async (sessionObj: any, confId: number) => {
  try {
    const result = await fetchPost(
      sessionObj,
      `/api/conference/${confId}/session`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const updateSession = async (
  sessionObj: any,
  confId: number,
  sessionId: number
) => {
  try {
    const result = await fetchPut(
      sessionObj,
      `/api/conference/${confId}/session/${sessionId}`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getSession = async (confId: number, sessionId: number) => {
  try {
    const result = await fetchGet(
      `/api/conference/${confId}/session/${sessionId}`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const deleteSession = function (confId: number, sessionId: number) {
  return fetch(`${serverURL}/api/conference/${confId}/session/${sessionId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const getSessions = async (confId: number) => {
  try {
    const result = await fetchGet(`/api/conference/${confId}/session`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getSessionDateList = async (confId: number) => {
  try {
    const result = await fetchGet(`/api/conference/${confId}/session-date`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getSessionsGroupByLocation = async (
  confId: number,
  date: string
) => {
  try {
    const result = await fetchGet(
      `/api/conference/${confId}/session-schedule/${date}`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const addSessionStaff = async (sessionId: number, staff: any) => {
  try {
    const result = await fetchPost(
      { staff: staff },
      `/api/session/${sessionId}/staff`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const updateSessionStaff = async (
  sessionStaffId: number,
  staff: any
) => {
  try {
    const result = await fetchPut(
      { staff: staff },
      `/api/sessionStaff/${sessionStaffId}`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const deleteSessionStaff = async (sessionStaffId: number) => {
  try {
    const result = await fetchDelete(`/api/sessionStaff/${sessionStaffId}`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const addPresentation = async (presentation: any) => {
  try {
    const result = await fetchPost(
      presentation,
      `/api/session/${presentation.sessionId}/presentation`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const updatePresentation = function (presentation: any) {
  return fetch(`${serverURL}/api/presentation/${presentation.id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(presentation),
    credentials: 'include'
  }).then((response) => response.json());
};

export const deletePresentation = function (sessionId: number, id: number) {
  return fetch(`${serverURL}/api/session/${sessionId}/presentation/${id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const deleteAllPresentation = function (sessionId: number) {
  return fetch(`${serverURL}/api/session/${sessionId}/presentation`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const addPresentationAuthor = async (
  presentationId: number,
  author: any
) => {
  try {
    const result = await fetchPost(
      { author: author },
      `/api/presentation/${presentationId}/author`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const updatePresentationAuthor = async (
  presentationAuthorId: number,
  author: any
) => {
  try {
    const result = await fetchPut(
      { author: author },
      `/api/presentationAuthor/${presentationAuthorId}`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const deletePresentationAuthor = async (
  presentationAuthorId: number
) => {
  try {
    const result = await fetchDelete(
      `/api/presentationAuthor/${presentationAuthorId}`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const addPresentationAttachment = async (
  presentationId: number,
  attachment: any
) => {
  try {
    const result = await fetchPost(
      { attachment: attachment },
      `/api/presentation/${presentationId}/attachment`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const updatePresentationAttachment = async (
  presentationAttachmentId: number,
  attachment: any
) => {
  try {
    const result = await fetchPut(
      { attachment: attachment },
      `/api/presentationAttachment/${presentationAttachmentId}`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const deletePresentationAttachment = async (
  presentationAttachmentId: number
) => {
  try {
    const result = await fetchDelete(
      `/api/presentationAttachment/${presentationAttachmentId}`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getEventsByConfId = function (confId: number) {
  return fetch(`${serverURL}/api/conference/${confId}/event`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const addEvent = function (newEvent: any) {
  return fetch(`${serverURL}/api/event`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ newEvent: newEvent }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const deleteEvent = function (eventId: number) {
  return fetch(`${serverURL}/api/event/${eventId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const getSponsorsByInstId = async function (instId: number) {
  try {
    const result = await fetchGet(`/api/sponsor/${instId}`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getSponsorsByConfId = async function (confId: number) {
  try {
    const result = await fetchGet(`/api/conference/${confId}/sponsor`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getSponsorGroupByConfId = async function (confId: number) {
  try {
    const result = await fetchGet(`/api/conference/${confId}/sponsorGroup`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const addSponsor = function (newSponsor: any) {
  return fetch(`${serverURL}/api/sponsor`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ newSponsor: newSponsor }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const addSponsored = function (
  newSponsored: any,
  confId: number,
  sponsorId: number
) {
  return fetch(`${serverURL}/api/conference/${confId}/sponsor/${sponsorId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ newSponsored: newSponsored }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const deleteSponsor = function (sponsoredId: number) {
  return fetch(`${serverURL}/api/sponsor/${sponsoredId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const updateSponsor = function (newSponsor: any, sponsorId: number) {
  return fetch(`${serverURL}/api/sponsor/${sponsorId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ newSponsor: newSponsor }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const updateSponsorGroupByConfId = function (
  newSponsorGroup: any,
  confId: number
) {
  return fetch(`${serverURL}/api/conference/${confId}/sponsorGroup`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ newSponsorGroup: newSponsorGroup }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const getConfBySponsor = async function (sponsorId: number) {
  return fetch(`${serverURL}/api/sponsor/${sponsorId}/conference`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const getSponsor = async function () {
  return await getInstitute().then((instRes) => {
    return getSponsorsByInstId(instRes.institute.id).then((sponsRes) => {
      return {
        instId: instRes.institute.id,
        instTitle: instRes.institute.title,
        sponsors: sponsRes.sponsors
      };
    });
  });
};

export const getPreSignedUrl = async (keyname: string, filename: any) => {
  try {
    const result = await fetchGet(
      `/api/presignedUrl?key=${keyname}&filename=${filename}`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const uploadToBucket = async (preSignedUrl: string, file: File) => {
  try {
    const option = {
      method: 'PUT',
      body: file
    };

    let result: any = await fetch(preSignedUrl, option);

    const s3Url = preSignedUrl.split('?')[0];
    if (!result) result = {};
    if (!result.data)
      result.data = {
        url: s3Url
      };

    return result;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getConfVideoAd = async function (
  confId: number,
  pageIdx: number,
  rowsPerPage: number
) {
  return fetch(
    //`${serverURL}/api/conference/${confId}/videoad/paginate?pageidx=${pageIdx}&rowperpage=${rowsPerPage}`,
    `${serverURL}/api/conference/${confId}/videoppl/paginate?pageidx=${pageIdx}&rowperpage=${rowsPerPage}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  ).then((response) => response.json());
};

export const addConfVideoAd = function (confId: number, newVideoAd: any) {
  //return fetch(`${serverURL}/api/conference/${confId}/videoad`, {
  return fetch(`${serverURL}/api/conference/${confId}/videoppl`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ newVideoAd: newVideoAd }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const updateVideoAd = function (videoAdId: number, newVideoAd: any) {
  //return fetch(`${serverURL}/api/videoad/${videoAdId}`, {
  return fetch(`${serverURL}/api/videoppl/${videoAdId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ newVideoAd: newVideoAd }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const deleteConfVideoAdSkip = function (confId: number) {
  //return fetch(`${serverURL}/api/conference/${confId}/videoad`, {
  return fetch(`${serverURL}/api/conference/${confId}/videoppl`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const deleteVideoAd = function (videoAdId: number) {
  return fetch(`${serverURL}/api/videoppl/${videoAdId}`, {
    //return fetch(`${serverURL}/api/videoAd/${videoAdId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const sendEmail = function (
  emailList: any,
  message: any,
  reservedTime: any
) {
  return fetch(serverURL + `/api/email`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      userList: emailList,
      content: message,
      isHtml: true,
      reservedTime: reservedTime
    }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const sendCustomerEmail = function (
  emailList: any,
  title: any,
  message: any,
  reservedTime: any
) {
  return fetch(serverURL + `/api/email/customer`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      userList: emailList,
      subject: title,
      content: message,
      isHtml: true,
      reservedTime: reservedTime
    }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const sendPasswordResetEmail = function (email: any) {
  return fetch(serverURL + `/api/email/passwordReset`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user: email
    }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const sendSMS = async function (
  smsList: any,
  message: any,
  reservedTime: any
) {
  return fetch(serverURL + `/api/sms`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      userList: smsList,
      content: message,
      reservedTime: reservedTime
    }),
    credentials: 'include'
  }).then((response) => response.json());
};

export const getConfRole = async function (confId: number) {
  return fetch(`${serverURL}/api/conference/${confId}/role`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const insertRole = async function (newRole: any) {
  return fetch(serverURL + `/api/role`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(newRole),
    credentials: 'include'
  }).then((response) => response.json());
};

export const getQna = async function (
  confId: number,
  pageIdx = 0,
  rowsPerPage = 5,
  answered = 2
) {
  return fetch(
    `${serverURL}/api/conference/${confId}/qna?pageidx=${pageIdx}&rowperpage=${rowsPerPage}&answered=${answered}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  ).then((response) => response.json());
};

export const getAllQna = async function (
  instId: number,
  pageIdx = 0,
  rowsPerPage = 5,
  answered = 2
) {
  return fetch(
    `${serverURL}/api/institute/${instId}/qna?pageidx=${pageIdx}&rowperpage=${rowsPerPage}&answered=${answered}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  ).then((response) => response.json());
};

export const updateQna = async function (qnaId: number, newQna: any) {
  return fetch(`${serverURL}/api/qna/${qnaId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(newQna),
    credentials: 'include'
  }).then((response) => response.json());
};

export const insertAttendeeXlsx = async function (confId: number, key: string) {
  try {
    const result = await fetchPost(
      { key: key },
      `/api/conference/${confId}/xlsx/attendee`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const insertCommitteeXlsx = async function (
  confId: number,
  key: string
) {
  try {
    const result = await fetchPost(
      { key: key },
      `/api/conference/${confId}/xlsx/committee`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getSponsorClickLog = async function (instId: number) {
  return fetch(`${serverURL}/api/institute/${instId}/log/sponsorClick`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const getConfSponsorClickLog = async function (confId: number) {
  return fetch(`${serverURL}/api/conference/${confId}/log/sponsorClick`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const getVisitLog = async function (instId: number) {
  return fetch(`${serverURL}/api/institute/${instId}/log/visit`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const getConfVisitLog = async function (confId: number) {
  return fetch(`${serverURL}/api/conference/${confId}/log/visit`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const getSessionVisitLog = async function (confId: number) {
  return fetch(`${serverURL}/api/conference/${confId}/session/log/visit`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const getConfJoinLiveLog = async function (confId: number) {
  return fetch(`${serverURL}/api/conference/${confId}/log/joinLive`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const postLog = function (url: string | null, src: string | null) {
  return fetch(`${serverURL}/api/conference/log`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ url, src }),
    credentials: 'include'
  }).then((res) => res.json());
};

export const getConfTicket = function (confId: number) {
  return fetch(`${serverURL}/api/conference/${confId}/ticket`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const postTicket = function (newTicket: any) {
  return fetch(`${serverURL}/api/ticket`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ newTicket: newTicket }),
    credentials: 'include'
  }).then((res) => res.json());
};

export const putTicket = function (ticketId: number, newTicket: any) {
  return fetch(`${serverURL}/api/ticket/${ticketId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ newTicket: newTicket }),
    credentials: 'include'
  }).then((res) => res.json());
};

export const deleteTicket = function (ticketId: number) {
  return fetch(`${serverURL}/api/ticket/${ticketId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((res) => res.json());
};

export const getConfPages = function (confId: number) {
  return fetch(`${serverURL}/api/conference/${confId}/conferencePage`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};

export const addConfPage = function (confId: number, newConferencePage: any) {
  return fetch(`${serverURL}/api/conference/${confId}/conferencePage`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ newConferencePage: newConferencePage }),
    credentials: 'include'
  }).then((res) => res.json());
};

export const updateConfPage = function (
  confPageId: number,
  newConferencePage: any
) {
  return fetch(`${serverURL}/api/conferencePage/${confPageId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ newConferencePage: newConferencePage }),
    credentials: 'include'
  }).then((res) => res.json());
};

export const deleteConfPage = function (confPageId: number) {
  return fetch(`${serverURL}/api/conferencePage/${confPageId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((res) => res.json());
};

export const addPageAttachment = function (
  confPageId: number,
  newPageAttachmentUrl: string
) {
  return fetch(`${serverURL}/api/conferencePage/${confPageId}/pageAttachment`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ newPageAttachmentUrl: newPageAttachmentUrl }),
    credentials: 'include'
  }).then((res) => res.json());
};

export const deletePageAttachment = function (
  confPageId: number,
  pageAttachmentUrl: string
) {
  return fetch(`${serverURL}/api/conferencePage/${confPageId}/pageAttachment`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ pageAttachmentUrl: pageAttachmentUrl }),
    credentials: 'include'
  }).then((res) => res.json());
};

export const updateOnlineBooth = function (
  sponsorId: number,
  onlineBooth: any
) {
  return fetch(`${serverURL}/api/sponsor/${sponsorId}/onlineBooth`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ onlineBooth: onlineBooth }),
    credentials: 'include'
  }).then((res) => res.json());
};

export const getSponsorVisit = function (sponsorId: number) {
  return fetch(`${serverURL}/api/sponsor/${sponsorId}/sponsorVisit`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).then((response) => response.json());
};
