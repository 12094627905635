import { ReactNode, useEffect, useState } from 'react';
import { useLocation, matchPath, Link, useHistory } from 'react-router-dom';
// material
import { experimentalStyled } from '@material-ui/core/styles';
import { Box, Button, Drawer, Hidden } from '@material-ui/core';
// components
import Scrollbar from '../../components/Scrollbar';

import { useRecoilState } from 'recoil';
import { instState } from '../../store/atom';
//
import styled, { css } from 'styled-components';
// ----------------------------------------------------------------------
import { ReactComponent as ChartIcon } from '../../assets/ic_charts.svg';
import { ReactComponent as ElementsIcon } from '../../assets/ic_elements.svg';
import { ReactComponent as UsersIcon } from '../../assets/ic_users.svg';
import { ReactComponent as UserIcon } from '../../assets/ic_user.svg';
import { ReactComponent as MapIcon } from '../../assets/ic_map.svg';
import { ReactComponent as CalendarIcon } from '../../assets/ic_calendar.svg';
import { ReactComponent as AlertIcon } from '../../assets/ic_alert.svg';
import { ReactComponent as VideoIcon } from '../../assets/ic_video.svg';
import { ReactComponent as TicketIcon } from '../../assets/ic_ticket.svg';

const DRAWER_WIDTH = 280;

const RootStyle = experimentalStyled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

// ----------------------------------------------------------------------

const LogoBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 14px 27px;
  box-sizing: border-box;
  height: 88px;
  width: 280px;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #d7d7d7;
  align-items: center;
  color: rgba(40, 42, 44, 0.68);
  cursor: pointer;
`;

const LogoImage = styled.div<{ backgroundUrl: string }>`
  width: 100%;
  height: 100%;
  ${({ backgroundUrl }) => css`
    background-image: url(${backgroundUrl});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  `}
`;

const LogoText = styled.h1`
  padding-left: 10px;
  font-size: 20px;
  line-height: 20px;
`;

const SidebarContents = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #d7d7d7;
  width: 280px;
  height: calc(100vh - 88px);
`;

const MenuGroupTitle = styled.div`
  margin-top: 24px;
  text-transform: uppercase;
  padding-left: 40px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.2px;
  font-weight: bold;
`;

const MenuList = styled.ul`
  margin-top: 16px;
`;

const MenuItem = styled.li``;

const MenuLink = styled(Box)<{ path: string; to: string }>`
  display: flex;
  align-items: center;
  padding: 12px 40px;
  position: relative;
  text-decoration: none;
  color: #465058;
  font-size: 14px;
  line-height: 14px;
  font-weight: normal;
  svg {
    fill: #637381;
    margin-right: 16px;
  }
  &:after {
    position: absolute;
    display: inline-block;
    content: '';
    height: 100%;
    width: 3px;
    top: 0%;
    right: -1px;
    background-color: #ffffff00;
    border-radius: 2px 0px 0px 2px;
  }
  &:hover {
    cursor: pointer;
  }
  ${({ path, to }) => {
    if (path === to.split('?')[0]) {
      return css`
        color: #4052ff;
        background-color: rgba(76, 95, 239, 0.08);
        font-weight: bold;
        &:after {
          background-color: #4052ff;
        }
        svg {
          fill: #4052ff;
        }
      `;
    }
  }}
`;

// ----------------------------------------------------------------------

type NavBarProps = {
  isOpenSidebar?: boolean;
  onCloseSidebar?: VoidFunction;
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar
}: NavBarProps) {
  const { pathname } = useLocation();
  const confId = new URLSearchParams(window.location.search).get('confId');
  const urlParam = confId ? `?confId=${confId}` : '';

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const [institute, setInstitute] = useRecoilState(instState);

  const history = useHistory();
  const sideberMenu = [
    {
      to: 'conferenceStatistic',
      icon: <ChartIcon />,
      text: '학술대회 통계'
    },
    {
      to: 'conference',
      icon: <ElementsIcon />,
      text: '기본 정보'
    },
    {
      to: 'ticket',
      icon: <TicketIcon />,
      text: '티켓 판매'
    },
    {
      to: 'attendee',
      icon: <UsersIcon />,
      text: '참석자'
    },
    {
      to: 'committee',
      icon: <UserIcon />,
      text: '초청 인원'
    },
    {
      to: 'location',
      icon: <MapIcon />,
      text: '행사 진행 장소'
    },
    {
      to: 'program',
      icon: <CalendarIcon />,
      text: '프로그램'
    },
    {
      to: 'news',
      icon: <AlertIcon />,
      text: '공지/알림'
    },
    {
      to: 'videoAd',
      icon: <VideoIcon />,
      text: '비디오 광고'
    }
  ];
  const renderContent = (
    <Scrollbar>
      <LogoBox
        onClick={() => {
          window.location.href = '/dashboard/main';
        }}
      >
        {institute?.thumbnail && (
          <LogoImage backgroundUrl={institute.thumbnail}></LogoImage>
        )}
        {!institute?.thumbnail && <LogoText>{institute?.title}</LogoText>}
      </LogoBox>
      <SidebarContents>
        <MenuGroupTitle>management</MenuGroupTitle>
        <MenuList>
          {sideberMenu.map((item, index) => (
            <MenuItem key={index}>
              <MenuLink
                path={history.location.pathname}
                to={`/conference/${item.to}${urlParam}`}
                onClick={() => {
                  history.push(`/conference/${item.to}${urlParam}`);
                }}
              >
                {item.icon}
                <span>{item.text}</span>
              </MenuLink>
            </MenuItem>
          ))}
        </MenuList>
      </SidebarContents>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <Hidden lgUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, border: 'none' }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              border: 'none'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </RootStyle>
  );
}
