import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { PATH_CONFERENCE } from './paths';

import AuthGuard from '../guards/AuthGuard';
// layouts
import ConferenceLayout from '../layouts/conference';

// ----------------------------------------------------------------------

const DashboardRoutes = {
  path: PATH_CONFERENCE.root,
  guard: AuthGuard,
  layout: ConferenceLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_CONFERENCE.general.conferenceStatistic,
      component: lazy(() => import('../views/conference/ConferenceStatistic'))
    },
    {
      exact: true,
      path: PATH_CONFERENCE.general.addConference,
      component: lazy(() => import('../views/conference/Conference'))
    },
    {
      exact: true,
      path: PATH_CONFERENCE.general.addAttendee,
      component: lazy(() => import('../views/conference/Attendee'))
    },
    {
      exact: true,
      path: PATH_CONFERENCE.general.addCommittee,
      component: lazy(() => import('../views/conference/Committee'))
    },
    {
      exact: true,
      path: PATH_CONFERENCE.general.bookmarker,
      component: lazy(() => import('../views/conference/Bookmarker'))
    },
    {
      exact: true,
      path: PATH_CONFERENCE.general.addProgram,
      component: lazy(() => import('../views/conference/Program'))
    },
    {
      exact: true,
      path: PATH_CONFERENCE.general.addNews,
      component: lazy(() => import('../views/conference/News'))
    },
    {
      exact: true,
      path: PATH_CONFERENCE.general.addNewsDetail,
      component: lazy(() => import('../views/conference/NewsDetail'))
    },
    {
      exact: true,
      path: PATH_CONFERENCE.general.addLocation,
      component: lazy(() => import('../views/conference/Location'))
    },
    {
      exact: true,
      path: PATH_CONFERENCE.general.videoAd,
      component: lazy(() => import('../views/conference/VideoAd'))
    },
    {
      exact: true,
      path: PATH_CONFERENCE.general.ticket,
      component: lazy(() => import('../views/conference/Ticket'))
    },

    // ----------------------------------------------------------------------

    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default DashboardRoutes;
