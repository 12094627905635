// material
import * as Yup from 'yup';
import { fbt } from 'fbt';
// material
import {
  Box,
  TextField,
  MenuItem,
  Typography,
  Dialog,
  FormControl,
  CardHeader,
  CardContent,
  Card,
  Button,
  Icon,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
// material
import { experimentalStyled } from '@material-ui/core/styles';

import BasicButton from '../../components/button/BasicButton';
import { CheckOutlined, Close, LocalOffer, Loyalty } from '@material-ui/icons';
import { useState } from 'react';

const TextBox = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const CaptionText = styled(Typography)`
  margin-top: 20px;
  margin-left: 10px;
  color: #637381;
`;

const PlanList = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

const StyledCard = styled(Card)`
  max-width: 380px;
  height: 400px;
  margin: 0px 10px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  width: 250px;
  &:hover {
    transform: scale(0.98);
    background-color: #97979730;
  }
`;

const Label = styled(Box)`
  top: 12px;
  right: 12px;
  position: absolute;
  width: 40px;
  padding: 3px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #1890ff29;
  color: #0c53b7;
  /* transform: rotate(45deg); */
`;

const CardTitle = styled(Typography)`
  color: #637381;
`;

const PriceBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin: 15px 0px;
`;

const MoneySymbol = styled(Typography)`
  color: #637381;
`;

const Price = styled(Typography)`
  margin: 0px 10px;
`;

const PricePer = styled(Typography)`
  align-self: flex-end;
  color: #637381;
`;

const ItemList = styled(Box)`
  margin: 30px 0px;
  width: 100%;
`;

const Item = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 14px;
`;

const ItemDisable = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 14px;
  color: #b8b8b8;
`;

const SelectButton = styled(Button)`
  position: absolute;
  bottom: 10px;
  width: 85%;
  height: 40px;
`;

const PayButton = styled(Button)`
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
`;

// ----------------------------------------------------------------------

interface Props {
  isOpen: boolean;
  close: () => void;
}

export const DialogPricing = ({ isOpen, close }: Props) => {
  const [selectedPlan, setSelectedPlan] = useState<number>(1);

  return (
    <Dialog open={isOpen} maxWidth="lg" onClose={close}>
      <TextBox>
        <Typography variant="h4">아리스 유료플랜</Typography>
        <CloseButton onClick={close}>
          <Close />
        </CloseButton>
        <CaptionText variant="caption">
          학회에 필요한 요금제를 선택한 후, ‘결제하기’ 버튼을 클릭해 주세요.
          <br />
          아리스 이용은 월 구독 개념으로 매월 자동 결제가 이루어집니다. (기본은
          Basic 결제로 설정되어 있어 무료입니다.)
          <br />
          구독을 취소하고 자동 결제를 취소할 경우 Basic 요금제로 선택한 후,
          ‘결제하기’ 버튼을 이용해 주세요.
        </CaptionText>
      </TextBox>
      <PlanList>
        {/* Basic plan */}
        <StyledCard
          sx={
            selectedPlan === 1
              ? { backgroundColor: '#97979730', transform: 'scale(0.98)' }
              : {}
          }
          onClick={() => setSelectedPlan(1)}
        >
          <CardTitle variant="overline">Basic</CardTitle>
          <PriceBox>
            <MoneySymbol variant="subtitle1">￦</MoneySymbol>
            <Price variant="h3">0</Price>
            <PricePer variant="subtitle2">/월</PricePer>
          </PriceBox>
          <ItemList>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} />{' '}
              학술대회 웹/앱 생성
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 기본
              정보 등록
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} />{' '}
              프로그램 등록
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} />{' '}
              공지/알림 기능
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 참가자
              관리
            </Item>
            <ItemDisable>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 라이브
              스트리밍
            </ItemDisable>
            <ItemDisable>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 비디오
              스트리밍
            </ItemDisable>
            <ItemDisable>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 후원사
              비디오 광고
            </ItemDisable>
          </ItemList>
        </StyledCard>

        {/* standard */}
        <StyledCard
          sx={
            selectedPlan === 2
              ? { backgroundColor: '#97979730', transform: 'scale(0.98)' }
              : {}
          }
          onClick={() => setSelectedPlan(2)}
        >
          <CardTitle variant="overline">Standard</CardTitle>
          <PriceBox>
            <MoneySymbol variant="subtitle1">￦</MoneySymbol>
            <Price variant="h3">100,000</Price>
            <PricePer variant="subtitle2">/월</PricePer>
          </PriceBox>
          <ItemList>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} />{' '}
              학술대회 웹/앱 생성
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 기본
              정보 등록
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} />{' '}
              프로그램 등록
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} />{' '}
              공지/알림 기능
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 참가자
              관리
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 라이브
              스트리밍
            </Item>
            <ItemDisable>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 비디오
              스트리밍
            </ItemDisable>
            <ItemDisable>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 후원사
              비디오 광고
            </ItemDisable>
          </ItemList>
        </StyledCard>

        {/* Premium */}
        <StyledCard
          sx={
            selectedPlan === 3
              ? { backgroundColor: '#97979730', transform: 'scale(0.98)' }
              : {}
          }
          onClick={() => setSelectedPlan(3)}
        >
          <Label>인기</Label>
          <CardTitle variant="overline">Premium</CardTitle>
          <PriceBox>
            <MoneySymbol variant="subtitle1">￦</MoneySymbol>
            <Price variant="h3">300,000</Price>
            <PricePer variant="subtitle2">/월</PricePer>
          </PriceBox>
          <ItemList>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} />{' '}
              학술대회 웹/앱 생성
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 기본
              정보 등록
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} />{' '}
              프로그램 등록
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} />{' '}
              공지/알림 기능
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 참가자
              관리
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 라이브
              스트리밍
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 비디오
              스트리밍
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 후원사
              비디오 광고
            </Item>
          </ItemList>
        </StyledCard>

        {/* Enterprise */}
        <StyledCard
          sx={
            selectedPlan === 4
              ? { backgroundColor: '#97979730', transform: 'scale(0.98)' }
              : {}
          }
        >
          <Label>인기</Label>
          <CardTitle variant="overline">Enterprise</CardTitle>
          <PriceBox>
            <Price variant="h4" paddingTop="10px">
              별도 문의
            </Price>
          </PriceBox>
          <ItemList>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} />
              학술대회 웹/앱 생성
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 기본
              정보 등록
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} />
              프로그램 등록
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} />
              공지/알림 기능
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 참가자
              관리
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 라이브
              스트리밍
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 비디오
              스트리밍
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 후원사
              비디오 광고
            </Item>
            <Item>
              <CheckOutlined sx={{ fontSize: '16px', marginRight: 1 }} /> 추가
              고객사 요구사항 반영
            </Item>
          </ItemList>
        </StyledCard>
      </PlanList>
      <PayButton
        size="large"
        variant="contained"
        onClick={() => alert('서비스 준비중입니다.')}
      >
        결제하기
      </PayButton>
    </Dialog>
  );
};
